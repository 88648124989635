import {
	Autocomplete,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	TextField,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IItemsJson } from "../../interfaces";
import { getCities, getDepartments } from "../../services/getOptions";

interface Props {
	nameState: string;
	nameCity: string;
}

export const DynamicLocationForm: FC<Props> = ({ nameState, nameCity }) => {
	const {
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const [stateOptions, setStateOptions] = useState<IItemsJson[]>([]);
	const [cityOptions, setCityOptions] = useState<IItemsJson[]>([]);

	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);

	const selectedState = watch(nameState);
	const selectedCities = watch(nameCity);

	useEffect(() => {
		const loadStates = async () => {
			setIsLoadingStates(true);
			const states = await getDepartments();
			setIsLoadingStates(false);
			setStateOptions(states);
		};
		loadStates();
	}, []);

	useEffect(() => {
		const loadCities = async () => {
			if (selectedState) {
				const value = stateOptions.find(
					({ text }) => text === selectedState,
				)?.value;
				setIsLoadingCities(true);
				const cities = await getCities(value?.toString() ?? "");
				setIsLoadingCities(false);
				setCityOptions(cities);
			} else {
				setCityOptions([]);
			}
		};
		loadCities();
	}, [selectedState, stateOptions]);

	const handleStateChange = (value: any) => {
		setValue(nameState, value ? value.text : "");
		setValue(nameCity, []);
	};

	return (
		<>
			<Grid item xs={12}>
				<InputLabel>Departamento</InputLabel>
				<FormControl fullWidth>
					<Controller
						name={nameState}
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id={nameState}
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={stateOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingStates}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) => handleStateChange(value)}
								value={
									stateOptions.find(
										(option) => option.text === selectedState,
									) || null
								}
								renderInput={(params) => {
									const { InputLabelProps, ...rest } = params;

									return (
										<TextField
											{...rest}
											fullWidth
											error={!!get(errors, nameState)}
											helperText={
												!!get(errors, nameState) &&
												get(errors, nameState).message
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoadingStates ? (
															<CircularProgress size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									);
								}}
							/>
						)}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12}>
				<InputLabel>Ciudad</InputLabel>
				<FormControl fullWidth>
					<Controller
						name={nameCity}
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id={nameCity}
								multiple
								readOnly={!selectedState}
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={cityOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingCities}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) =>
									setValue(
										nameCity,
										value.map((v: any) => v.text),
									)
								}
								value={
									cityOptions.filter((option) =>
										selectedCities?.includes(option.text),
									) || []
								}
								renderInput={(params) => {
									const { InputLabelProps, ...rest } = params;

									return (
										<TextField
											{...rest}
											fullWidth
											error={!!get(errors, nameCity)}
											helperText={
												!!get(errors, nameCity) && get(errors, nameCity).message
											}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoadingCities ? (
															<CircularProgress size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									);
								}}
							/>
						)}
					/>
				</FormControl>
			</Grid>
		</>
	);
};
