import { Box, Grid, Pagination } from "@enerbit/base";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BestTeamItem } from ".";
import { useTeamStore } from "../../store";
import { EmptyData } from "../empty-data";
import BestTeamItemSkeleton from "./BestItemSkeleton";

const BestTeamContainer = () => {
	const { loadingTeam, listTeams } = useTeamStore();
	const [page, setPage] = useState(1);

	const { watch } = useFormContext();

	const itemsPerPage = 3;
	const pageCount = Math.ceil(listTeams.length / itemsPerPage);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const startIndex = (page - 1) * itemsPerPage;
	const endIndex = page * itemsPerPage;

	return (
		<>
			<Grid container spacing={3} sx={{ pt: 3 }}>
				{loadingTeam ? (
					<>
						{Array.from({ length: itemsPerPage }).map((_, index) => (
							<BestTeamItemSkeleton key={index.toString()} />
						))}
					</>
				) : (
					<>
						{listTeams.length > 0 ? (
							<>
								{listTeams.slice(startIndex, endIndex).map((item, index) => (
									<BestTeamItem
										item={item}
										key={index.toString()}
										index={index.toString()}
									/>
								))}
							</>
						) : (
							<>
								{watch("planned_date_begin") && watch("planned_date_end") && (
									<Grid item xs={12}>
										<EmptyData
											message={
												"No hay cuadrillas disponibles para esta esta orden de servicio"
											}
										/>
									</Grid>
								)}
							</>
						)}
					</>
				)}
			</Grid>
			<Box sx={paginationContainerStyled}>
				<Pagination
					count={pageCount}
					color="primary"
					onChange={handleChange}
					page={page}
				/>
			</Box>
		</>
	);
};

//------------Styles-------------//

const paginationContainerStyled = {
	display: "flex",
	justifyContent: "center",
	my: 1.5,
};

export default BestTeamContainer;
