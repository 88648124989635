import {
	AddIcon,
	Box,
	Button,
	CloseIcon,
	ContentCopyIcon,
	Grid,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	dayjs,
	useSnackbar,
} from "@enerbit/base";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { type FC, useEffect } from "react";
import { get, useFormContext } from "react-hook-form";
import type { IDynamicForm, IItemsJson } from "../../../interfaces";
import { FormItem } from "../FormItem";

type Schedule = {
	since: string;
	until: string;
};

type ScheduleItemProps = {
	item: IItemsJson;
	name: string;
};

const ScheduleItem: FC<ScheduleItemProps> = ({ name, item }) => {
	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();
	const { enqueueSnackbar } = useSnackbar();
	const isOpen = Boolean(watch(name));

	const toggleOpen = () => {
		if (isOpen) {
			setValue(name, null);
		} else {
			setValue(name, { since: "", until: "" });
		}
	};

	const handleCopyScheduleItem = async () => {
		try {
			const scheduleItem = watch(name);

			const formattedScheduleItem = {
				since:
					dayjs.isDayjs(scheduleItem?.since) && scheduleItem?.since.isValid()
						? scheduleItem.since.utc().format("HH:mm:ss.SSS[Z]")
						: scheduleItem.since,
				until:
					dayjs.isDayjs(scheduleItem?.until) && scheduleItem?.until.isValid()
						? scheduleItem.until.utc().format("HH:mm:ss.SSS[Z]")
						: scheduleItem.until,
			};

			await navigator.clipboard.writeText(
				JSON.stringify(formattedScheduleItem),
			);
			enqueueSnackbar("Horario copiado correctamente.", {
				variant: "success",
			});
		} catch (err) {
			enqueueSnackbar(
				"Error al copiar el horario. Por favor, intente de nuevo.",
				{ variant: "error" },
			);
		}
	};

	const handlePasteScheduleItem = async () => {
		try {
			const text = await navigator.clipboard.readText();
			const scheduleItem: Schedule = JSON.parse(text);

			if (scheduleItem?.since && scheduleItem?.until) {
				setValue(name, scheduleItem);
				enqueueSnackbar("Horario pegado correctamente.", {
					variant: "success",
				});
			} else {
				const parsedText = JSON.parse(text);
				const daySchedule = parsedText[name.split(".").pop() || ""];
				if (daySchedule?.since && daySchedule?.until) {
					setValue(name, daySchedule);
					enqueueSnackbar("Horario pegado correctamente.", {
						variant: "success",
					});
				} else {
					enqueueSnackbar("El horario pegado no es válido.", {
						variant: "warning",
					});
				}
			}
		} catch (err) {
			enqueueSnackbar(
				"Error al pegar el horario. Asegúrese de que los datos copiados sean correctos.",
				{ variant: "error" },
			);
		}
	};

	return (
		<Paper sx={{ borderRadius: "12px", p: 2, border: "1px solid #E4E7EC" }}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				gap={2}
			>
				<Box>
					<Typography color="#667085">Dia</Typography>
					<Typography color="#344054" fontWeight="700" fontSize="16px">
						{item.text}
					</Typography>
				</Box>
				{isOpen ? (
					<Grid container spacing={2} display="flex" alignItems="center">
						<FormItem
							type="time"
							name={`${name}.since`}
							label="Inicio"
							sx={{
								xs: 4,
							}}
						/>
						<FormItem
							type="time"
							name={`${name}.until`}
							props={{
								disabled: !watch(`${name}.since`),
								minTime: dayjs(watch(`${name}.since`)),
							}}
							label="Finalización"
							sx={{
								xs: 4,
							}}
						/>
						<Grid item xs={4} display="flex" justifyContent="center">
							<Box display="flex" alignItems="center" gap={1}>
								<Tooltip title="Eliminar horario">
									<IconButton
										id={`clear-${name}`}
										size="small"
										onClick={toggleOpen}
									>
										<CloseIcon fontSize="small" />
									</IconButton>
								</Tooltip>
								<Tooltip title="Pegar horario">
									<IconButton
										id={`paste-${name}`}
										size="small"
										onClick={handlePasteScheduleItem}
									>
										<ContentPasteIcon fontSize="small" />
									</IconButton>
								</Tooltip>
								<Tooltip title="Copiar horario">
									<IconButton
										id={`copy-${name}`}
										size="small"
										onClick={handleCopyScheduleItem}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Box>
						</Grid>
					</Grid>
				) : (
					<Button
						id={`add-${name}`}
						size="small"
						variant="outlined"
						color="secondary"
						onClick={toggleOpen}
						startIcon={<AddIcon fontSize="small" />}
					>
						Agregar horario
					</Button>
				)}
				{isOpen && get(errors, name) && (
					<Typography color="error">{get(errors, name).message}</Typography>
				)}
			</Box>
		</Paper>
	);
};

export const ScheduleForm: FC<IDynamicForm> = ({ items, name, label }) => {
	if (!name) return null;

	const { watch, setValue } = useFormContext();

	const formData = watch();

	useEffect(() => {
		console.log(formData);
	}, [formData]);
	const { enqueueSnackbar } = useSnackbar();

	const handleCopySchedule = async () => {
		try {
			const weekSchedule = watch(name);

			// Convertir cada horario a string en formato UTC si es un objeto dayjs
			const formattedWeekSchedule = Object.keys(weekSchedule).reduce(
				(acc, day) => {
					const daySchedule = weekSchedule[day];
					const since = daySchedule.since;
					const until = daySchedule.until;

					acc[day] = {
						since:
							dayjs.isDayjs(since) && since.isValid()
								? since.utc().format("HH:mm:ss.SSS[Z]")
								: since,
						until:
							dayjs.isDayjs(until) && until.isValid()
								? until.utc().format("HH:mm:ss.SSS[Z]")
								: until,
					};
					return acc;
				},
				{} as Record<string, Schedule>,
			);

			await navigator.clipboard.writeText(
				JSON.stringify(formattedWeekSchedule),
			);
			enqueueSnackbar("Horario copiado correctamente.", {
				variant: "success",
			});
		} catch (err) {
			enqueueSnackbar(
				"Error al copiar el horario. Por favor, intente de nuevo.",
				{ variant: "error" },
			);
		}
	};

	const handlePasteSchedule = async () => {
		try {
			const text = await navigator.clipboard.readText();
			const parsedText = JSON.parse(text);

			if (typeof parsedText === "object" && parsedText !== null) {
				// Verifica si es un horario de un solo día
				const isSingleDaySchedule =
					(parsedText as Schedule).since !== undefined &&
					(parsedText as Schedule).until !== undefined;

				if (isSingleDaySchedule) {
					// Si es un horario de un solo día, aplica a todos los días
					for (const item of items || []) {
						setValue(`${name}.${item.value}`, parsedText as Schedule);
					}
					enqueueSnackbar("Horario pegado a todos los días correctamente.", {
						variant: "success",
					});
				} else {
					// Si es un horario de varios días, aplica cada uno
					const expectedDaysSet = new Set(items?.map((item) => item.value));
					const isValidSchedule =
						Object.keys(parsedText).every((day) => expectedDaysSet.has(day)) &&
						Object.keys(parsedText).length === items?.length;

					if (isValidSchedule) {
						for (const [day, schedule] of Object.entries(parsedText)) {
							const scheduleDay = schedule as Schedule;
							if (scheduleDay?.since && scheduleDay?.until) {
								setValue(`${name}.${day}`, scheduleDay);
							}
						}
						enqueueSnackbar("Horario actualizado correctamente.", {
							variant: "success",
						});
					} else {
						enqueueSnackbar(
							"El horario pegado no corresponde con los días esperados.",
							{ variant: "warning" },
						);
					}
				}
			} else {
				enqueueSnackbar("El horario pegado no es válido.", {
					variant: "warning",
				});
			}
		} catch (err) {
			enqueueSnackbar(
				"Error al actualizar el horario. Asegúrese de que los datos copiados sean correctos.",
				{ variant: "error" },
			);
		}
	};

	return (
		<Grid item xs={12}>
			<Paper
				sx={{
					borderRadius: "12px",
					p: 2,
					border: "1px solid #A3A9B6",
					gap: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography variant="h6" color={"primary"}>
						{label}
					</Typography>
					<Box sx={{ display: "flex", gap: 1 }}>
						<Tooltip title={"Pegar horario"}>
							<IconButton
								id={`paste-${name}`}
								size="small"
								onClick={handlePasteSchedule}
							>
								<ContentPasteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Copiar horario">
							<IconButton
								id={`copy-${name}`}
								size="small"
								onClick={handleCopySchedule}
							>
								<ContentCopyIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
					{items?.map((item, index) => (
						<ScheduleItem
							key={index.toString()}
							item={item}
							name={`${name}.${item.value}.0`}
						/>
					))}
				</Box>
			</Paper>
		</Grid>
	);
};
