import { Grid, LoadingButton, decodedJwt, useSnackbar } from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import type { IPatchOrders } from "../../interfaces";
import { useOrderStore } from "../../store";
import { useSelectedOrderStore } from "../../store/selectedOrder";
import {
	coordination,
	getFinalMaxDate,
	getFinalMinDate,
	getFinalMinTime,
} from "../../utils";
import { BestTeamContainer } from "../Teams";
import { FormItem } from "../dynamic-form/FormItem";

const UpdateOrderForm = () => {
	const { enqueueSnackbar } = useSnackbar();

	const { updateOrder, loadingUpdatedOrder, orders } = useOrderStore();
	const decodedToken = decodedJwt();

	const role = decodedToken?.role;

	const { selectedOrder, handleCloseOrder } = useSelectedOrderStore();

	const methods = useFormContext<IPatchOrders>();

	const { handleSubmit, watch, trigger, setValue } = methods;

	const availableCoordinations = coordination.find(
		({ code }) => code === selectedOrder?.resource.order_type.code,
	);

	const onSubmit = async (data: IPatchOrders) => {
		if (!(await trigger())) return;

		const newOrderStart = new Date(data.planned_date_begin).getTime();
		const newOrderEnd = new Date(data.planned_date_end).getTime();
		const workGroupId = watch("work_group_id");

		const overlap = orders.some((order) => {
			if (!workGroupId) return false;
			if (order.resource.work_group_id !== workGroupId) return false;

			const existingOrderStart = new Date(
				order.resource.planned_date_begin,
			).getTime();
			const existingOrderEnd = new Date(
				order.resource.planned_date_end,
			).getTime()

			const startDiff = Math.abs(newOrderStart - existingOrderEnd);
			const endDiff = Math.abs(newOrderEnd - existingOrderStart);

			return (
				startDiff < 900000 ||
				endDiff < 900000 ||
				(newOrderStart < existingOrderEnd && newOrderEnd > existingOrderStart)
			);
		});

		if (overlap) {
			enqueueSnackbar(
				"La cuadrilla debe tener 15 minutos de diferencia entre las ordenes de servicio.",
				{
					variant: "warning",
				},
			);
			return;
		}

		const selectedCoordinations =
			data.form_data?.criteria?.coordinations.filter(
				(value) => value !== "pre_enlisted_materials",
			) || [];

		const allCoordinationsSelected =
			selectedCoordinations.length ===
			availableCoordinations?.coordinations.filter(
				({ value }) => value !== "pre_enlisted_materials",
			).length;

		let newStatus = "";
		if (allCoordinationsSelected && workGroupId) {
			newStatus = "pending";
		} else {
			newStatus = "pending_assignment";
		}

		const updatedData = {
			...data,
			status: newStatus,
		};

		await updateOrder(
			selectedOrder?.resource.id ?? "",
			updatedData,
			workGroupId,
		);
		handleCloseOrder();
	};

	const plannedBeginDate = new Date(watch("planned_date_begin"));
	const plannedEndDate = new Date(watch("planned_date_end"));

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<FormItem
						type={"date-time"}
						label="Fecha y hora de inicio"
						name="planned_date_begin"
						sx={{
							xs: 12,
							lg: 6,
						}}
						props={{
							disablePast: true,
							disabled: role === "contratista",
						}}
					/>
					<FormItem
						type={"date-time"}
						label="Fecha y hora de finalización"
						name="planned_date_end"
						sx={{
							xs: 12,
							lg: 6,
						}}
						props={{
							disablePast: true,
							maxDate: getFinalMaxDate(plannedBeginDate),
							minTime: getFinalMinTime(plannedBeginDate, plannedEndDate),
							minDate: getFinalMinDate(plannedBeginDate),
							disabled: role === "contratista",
						}}
					/>
					{availableCoordinations &&
						availableCoordinations?.coordinations.length > 0 && (
							<FormItem
								type={"checkbox-group"}
								label="Coordinaciones"
								name="form_data.criteria.coordinations"
								items={availableCoordinations?.coordinations}
								sx={{
									xs: 12,
								}}
								props={{ disabled: role === "contratista" }}
							/>
						)}
				</Grid>
				<BestTeamContainer />
				<LoadingButton
					id="update-order"
					loading={loadingUpdatedOrder}
					fullWidth
					type="submit"
					color="secondary"
					variant="contained"
					sx={{ mt: 3 }}
				>
					Actualizar
				</LoadingButton>
			</form>
		</>
	);
};

export default UpdateOrderForm;
