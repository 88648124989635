import {
	Box,
	Chip,
	Typography,
	capitalize,
	enerbitColors,
} from "@enerbit/base";
import type { FC } from "react";
import type { IOperatorItem } from "../../interfaces";
import { StatusUser } from "../Teams";

interface Props {
	option: IOperatorItem;
}

export const OptionOperator: FC<Props> = ({ option }) => {
	const chipStyle = {
		background: "#F2F4F7",
		color: "#3D4350",
		fontWeight: "bold",
		m: 1,
	};

	const chipStyleCoordinator = {
		background: enerbitColors.information[200],
		color: enerbitColors.information.main,
		fontWeight: "bold",
		m: 1,
	};

	return (
		<>
			{option.pii && (
				<Box display="flex" flexDirection="column" flexGrow={1} p={2} gap={1}>
					<Box display="flex" flexWrap="wrap" gap={2} alignItems={"center"}>
						<Box flexGrow={1} flexShrink={1} maxWidth="calc(50% - 16px)" p={1}>
							<Typography color="#667085">Nombre</Typography>
							<Typography color="#344054" fontWeight="700" fontSize="16px">
								{option.pii.full_name}
							</Typography>
						</Box>
						<Box flexGrow={1} flexShrink={1} maxWidth="calc(50% - 16px)" p={1}>
							<Typography color="#667085">Correo electrónico</Typography>
							<Typography color="#344054" fontWeight="700" fontSize="16px">
								{option.username}
							</Typography>
						</Box>
						<Box flexGrow={1} flexShrink={1} maxWidth="calc(50% - 16px)" p={1}>
							<Typography color="#667085">Empresa</Typography>
							<Typography color="#344054" fontWeight="700" fontSize="16px">
								{option?.company?.name}
							</Typography>
						</Box>
						<Box flexGrow={1} flexShrink={1} maxWidth="calc(50% - 16px)" p={1}>
							<Typography color="#667085">Rol</Typography>
							<Typography color="#344054" fontWeight="700" fontSize="16px">
								{option?.rol?.name}
							</Typography>
						</Box>
					</Box>
					<Box display="flex" flexWrap="wrap" gap={1}>
						<StatusUser status={option.is_active} type={"user"} />
						<StatusUser status={option.pii.status === "enabled"} type={"doc"} />
						{option.pii.form_data?.coordinator && (
							<Chip label={"Coordinador"} sx={chipStyleCoordinator} />
						)}
						{option?.pii?.state && (
							<Chip label={option.pii.state} sx={chipStyle} />
						)}
						{option?.pii?.city && (
							<Chip label={option.pii.city} sx={chipStyle} />
						)}
						{option.pii.form_data?.measurement_type_trained?.map(
							(value, key) => (
								<Chip
									key={key.toString()}
									label={capitalize(value)}
									sx={chipStyle}
								/>
							),
						)}
					</Box>
				</Box>
			)}
		</>
	);
};
