import {
	AdapterDayjs,
	Grid,
	InputLabel,
	LocalizationProvider,
	TimePicker,
	dayjs,
} from "@enerbit/base";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import type { FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";
import "dayjs/locale/es";

dayjs.extend(utc);
dayjs.extend(timezone);

export const TimeForm: FC<IDynamicForm> = ({ label, name, sx, props }) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();

	const currentValue = name && watch(name);

	const shouldDisableTime = (time: dayjs.Dayjs | null): boolean => {
		return false;
	};

	const handleTimeChange = (time: dayjs.Dayjs | null) => {
		if (name) {
			if (time) {
				const utcTime = time.utc();
				setValue(name, utcTime);
			} else {
				setValue(name, null);
			}
		}
	};

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<InputLabel className="title-input-edition">{label}</InputLabel>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
				<Controller
					name={name}
					control={control}
					defaultValue={null}
					render={({ field: { value } }) => (
						<TimePicker
							closeOnSelect={false}
							ampm
							value={
								typeof value === "string"
									? dayjs.utc(value, "HH:mm:ss").tz("America/Bogota")
									: value
										? value.tz("America/Bogota")
										: null
							}
							onChange={handleTimeChange}
							disabled={props?.disabled}
							minTime={props?.minTime}
							slotProps={{
								textField: {
									id: name,
									color: "primary",
									helperText: get(errors, name)
										? get(errors, name).message
										: "",
									error: !!get(errors, name),
									sx: {
										"& fieldset": { borderRadius: "14px" },
										"& p": { color: "red" },
										width: "100%",
									},
								},
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		</Grid>
	);
};
