import {
	Box,
	Button,
	DialogContent,
	Typography,
	decodedJwt,
	enerbitColors,
} from "@enerbit/base";

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as singleSpa from "single-spa";
import {
	StyledDialog,
	StyledDialogTitle,
	StyledTab,
	StyledTabs,
	TabPanel,
} from "../../common";
import type { IPatchOrders } from "../../interfaces";
import { schemaOrder } from "../../schemas";
import { useOrderStore, useTeamStore } from "../../store";
import { useSelectedOrderStore } from "../../store/selectedOrder";
import InfoOrder from "./InfoOrder";
import UpdateOrderForm from "./UpdateOrderForm";

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};

export const OrderItemModal = () => {
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const {
		selectedOrder,
		handleCloseOrder,
		getOutPutData,
		previous_diagnostic,
	} = useSelectedOrderStore();

	const order_type = selectedOrder?.resource.order_type;

	const { searchTeams } = useTeamStore();
	const { orders } = useOrderStore();
	const decodedToken = decodedJwt();

	const role = decodedToken?.role;
	const company_id = decodedToken?.company_id;

	const [valueTab, setValueTab] = useState(0);

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValueTab(newValue);
	};

	const methods = useForm<IPatchOrders>({
		resolver: zodResolver(schemaOrder),
		defaultValues: {
			...selectedOrder?.resource,
			status: "pending_assignment",
			form_data: {
				...selectedOrder?.resource.form_data,
				criteria: {
					cancellation_date_xm:
						selectedOrder?.resource.form_data.criteria?.cancellation_date_xm ??
						null,
					coordinations: [],
					...selectedOrder?.resource.form_data.criteria,
				},
			},
		},
		mode: "onChange",
	});

	const { watch, setValue } = methods;

	useEffect(() => {
		if (role) {
			if (isFirstLoad) {
				setIsFirstLoad(false);
			} else {
				setValue("work_group_id", null);
			}

			const measurement_type =
				previous_diagnostic?.form_data.diagnostic.measurement_type;

			if (watch("planned_date_begin") && watch("planned_date_end")) {
				searchTeams(
					selectedOrder?.resource.work_group_id ?? null,
					watch("state"),
					watch("city"),
					watch("planned_date_begin"),
					watch("planned_date_end"),
					role === "contratista" ? company_id : undefined,
					measurement_type,
				);
			}
		}
	}, [
		watch("planned_date_begin"),
		watch("planned_date_end"),
		selectedOrder,
		previous_diagnostic,
	]);

	useEffect(() => {
		if (
			role !== "contratista" &&
			!(
				order_type?.code === "IPCCE" ||
				order_type?.code === "IG" ||
				order_type?.code === "INS_SOLAR"
			)
		) {
			if (selectedOrder && selectedOrder?.resource.order_entities.length > 0) {
				getOutPutData(
					selectedOrder?.resource.order_entities[0].assignee_id ?? "",
					selectedOrder?.resource.order_entities[0].entity.code === "LD",
				);
			}
		}
	}, [role, order_type]);

	return (
		<StyledDialog open={!!selectedOrder} maxWidth="md" fullWidth scroll="paper">
			<StyledDialogTitle onClose={handleCloseOrder} id={"id-confirm-edit"}>
				<Typography
					sx={{
						color: "#53358E",
						fontSize: "25px",
						lineHeight: "47px",
						letterSpacing: "-0.02em",
					}}
				>
					{selectedOrder?.title}
				</Typography>
			</StyledDialogTitle>
			<DialogContent>
				<Box sx={{ display: "flex" }}>
					<Box
						sx={{
							borderRadius: "4px",
							p: 0.25,
							backgroundColor:
								selectedOrder?.resource.status === "pending"
									? enerbitColors.success.main
									: enerbitColors.neutral.main,
						}}
					>
						<Typography fontSize={"12px"} fontWeight={"bold"} color={"white"}>
							{selectedOrder?.resource.status === "pending"
								? "Para ejecución"
								: "En preparación"}
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<StyledTabs value={valueTab} onChange={handleChange}>
						<StyledTab label={<Box>Edición</Box>} {...a11yProps(0)} />
						<StyledTab label={<Box>Información</Box>} {...a11yProps(1)} />
					</StyledTabs>
					{selectedOrder?.resource.order_entities &&
						selectedOrder?.resource.order_entities.length > 0 && (
							<Box>
								<Button
									variant="contained"
									color="error"
									size="small"
									target="_blank"
									href={`/#/orders/cancel/${selectedOrder?.resource.id}?entity=${selectedOrder?.resource.order_entities[0].entity.code}&assignee=${selectedOrder?.resource.order_entities[0].assignee_id}`}
								>
									Ir a cancelar
								</Button>
							</Box>
						)}
				</Box>
				<TabPanel value={valueTab} index={0}>
					<FormProvider {...methods}>
						<UpdateOrderForm />
					</FormProvider>
				</TabPanel>
				<TabPanel value={valueTab} index={1}>
					<InfoOrder />
				</TabPanel>
			</DialogContent>
		</StyledDialog>
	);
};
