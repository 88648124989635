import {
	Box,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
} from "@enerbit/base";
import type { FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type { IDynamicForm, IItemsJson } from "../../../interfaces";

export const CheckboxGroupForm: FC<IDynamicForm> = ({
	name,
	label,
	items,
	sx,
	props,
}) => {
	const {
		control,
		formState: { errors },
		watch,
	} = useFormContext();

	if (!name || !watch(name)) return null;

	return (
		<Grid container item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Grid item xs={12}>
				<InputLabel>
					{label}
					{!!get(errors, name) && (
						<FormHelperText error>
							{" "}
							({get(errors, name).message})
						</FormHelperText>
					)}
				</InputLabel>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "start", gap: 4 }}>
					{items?.map((item: IItemsJson, index: number) => (
						<FormControlLabel
							key={index.toString()}
							sx={{
								"& .MuiFormControlLabel-label": {
									pt: 0.5,
								},
							}}
							control={
								<Controller
									name={name}
									control={control}
									render={({ field }) => (
										<Checkbox
											{...field}
											id={`${name}-${index}`}
											sx={{ py: 0 }}
											disabled={props?.disabled}
											checked={
												field?.value ? field?.value.includes(item.value) : false
											}
											onChange={(e) => {
												const newValue = Array.isArray(field.value)
													? [...field.value]
													: [];
												if (e.target.checked) {
													newValue.push(item.value);
												} else {
													const index = newValue.indexOf(item.value);
													if (index >= 0) {
														newValue.splice(index, 1);
													}
												}
												field.onChange(newValue);
											}}
										/>
									)}
								/>
							}
							label={item.text}
						/>
					))}
				</Box>
			</Grid>
		</Grid>
	);
};
