import {
	AddIcon,
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	DeleteIcon,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	TextField,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { type FC, useCallback, useEffect, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import type { IDynamicForm } from "../../../interfaces";
import { EmptyData } from "../../empty-data";

export const CustomAutocompleteForm: FC<IDynamicForm> = ({
	name,
	label,
	sx,
	renderItem,
	fetchItems,
	optionLabel,
}) => {
	if (!name) return null;
	if (!optionLabel) return null;

	const {
		control,
		formState: { errors },
		setValue,
		watch,
	} = useFormContext();

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const [selectedOptions, setSelectedOptions] = useState<any[]>(
		watch(name) ?? [],
	);
	const [inputValue, setInputValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const debouncedFetchItems = useDebouncedCallback(async (value: string) => {
		if (fetchItems) {
			setIsLoading(true);
			const fetchedItems = await fetchItems(value);
			setIsLoading(false);
			setOptions(fetchedItems);
		}
	}, 700);

	const OnAddOption = useCallback(
		(item: any) => {
			setSelectedOptions((prevOptions) => {
				const newOptions = [...prevOptions, item];
				setValue(name!, newOptions);
				return newOptions;
			});
		},
		[name, setValue],
	);

	const OnRemoveOption = useCallback(
		(id: any) => {
			setSelectedOptions((prevOptions) => {
				const newOptions = prevOptions.filter((item) => item.id !== id);
				setValue(name!, newOptions);
				return newOptions;
			});
		},
		[name, setValue],
	);

	useEffect(() => {
		if (inputValue) {
			debouncedFetchItems(inputValue);
		}
	}, [inputValue, debouncedFetchItems]);

	const renderOption = (item: any, props?: any) => {
		const Component = renderItem as React.FC<any>;
		return <Component {...props} option={item} />;
	};

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Box
				sx={{
					borderRadius: "12px",
					border: "1px solid #A3A9B6",
					p: 2,
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Typography variant="h6" color={"primary"}>
					Seleccionar
				</Typography>
				<Box>
					<InputLabel>{label}</InputLabel>
					<FormControl fullWidth>
						<Controller
							name={name}
							control={control}
							render={({ field: { onChange, ...field } }) => (
								<Autocomplete
									{...field}
									id={name}
									componentsProps={{
										popper: {
											sx: {
												zIndex: 1400,
											},
										},
									}}
									fullWidth
									sx={{ "& fieldset": { borderRadius: "14px" } }}
									open={open}
									onInputChange={(_, value) => {
										setInputValue(value);
									}}
									onOpen={() => setOpen(true)}
									onClose={() => setOpen(false)}
									options={options}
									getOptionLabel={(option) => option?.[optionLabel] ?? ""}
									renderOption={(props, item) => {
										return (
											<Box
												display="flex"
												justifyContent="space-between"
												gap={3}
												sx={{ borderBottom: "1px solid #667085" }}
											>
												{renderOption(item, props)}
												<Box
													sx={{ display: "flex", alignItems: "center" }}
													p={2}
												>
													<Button
														id="add-item"
														disabled={selectedOptions.find(
															({ id }) => item.id === id,
														)}
														className="orange-button"
														variant="contained"
														size="small"
														onClick={() => OnAddOption(item)}
													>
														<AddIcon fontSize="small" />
													</Button>
												</Box>
											</Box>
										);
									}}
									loading={isLoading}
									noOptionsText="Sin opciones."
									loadingText="Cargando..."
									onChange={(_, newValue) => onChange(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{isLoading ? <CircularProgress size={20} /> : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									)}
								/>
							)}
						/>
					</FormControl>
					{selectedOptions.length > 0 ? (
						<>
							{selectedOptions.map((item, index) => {
								return (
									<Box
										key={index.toString()}
										display="flex"
										justifyContent="space-between"
										gap={3}
										mt={3}
										borderRadius={"14px"}
										sx={{ border: `1px solid ${enerbitColors.neutral[300]}` }}
									>
										{renderOption(item)}
										<Box sx={{ display: "flex", alignItems: "center" }} p={2}>
											<Button
												id={`delete-${index}`}
												className="orange-button"
												variant="contained"
												size="small"
												onClick={() => OnRemoveOption(item.id)}
											>
												<DeleteIcon fontSize="small" />
											</Button>
										</Box>
									</Box>
								);
							})}
						</>
					) : (
						<Box pt={3}>
							<EmptyData message={"Aún no has seleccionado nada."} />
							{!!get(errors, name) && (
								<FormHelperText error>
									{get(errors, name).message}
								</FormHelperText>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</Grid>
	);
};
