import {
	Box,
	CircularProgress,
	Grid,
	decodedJwt,
	useSnackbar,
} from "@enerbit/base";
import { addMinute } from "@formkit/tempo";
import moment from "moment";
import { useCallback, useEffect } from "react";
import {
	Calendar,
	type Event,
	type View,
	momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop, {
	type EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";
import type { IEventOrder } from "../../interfaces";
import { useOrderStore } from "../../store";
import { useSelectedOrderStore } from "../../store/selectedOrder";
import {
	agendaTimeRangeFormat,
	getEventStyles,
	slotPropGetter,
} from "../../utils";
import { OrderItemModal } from "../order/OrderItemModal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { CustomEvent } from "./CustomEvent";
import { CustomTimeGutterHeader } from "./CustomTimeGutterHeader";
import CustomToolbar from "./CustomToolbar";

moment.locale("es");
const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar as React.ComponentType<any>);

const DynamicCalendar = () => {
	const {
		setCurrentDate,
		view,
		setView,
		orders,
		getOrders,
		currentDate,
		loadingOrders: loading,
		updateDateTimeOrder,
		departments,
	} = useOrderStore();

	const { selectedState, selectedCity, selectedStatus } = useOrderStore(
		(state) => state.filters,
	);

	const decodedToken = decodedJwt();

	const role = decodedToken?.role;
	const company_id = decodedToken?.company_id;

	const { handleOpenOrder, selectedOrder } = useSelectedOrderStore();

	const { enqueueSnackbar } = useSnackbar();

	const isEventOverlap = (newStart: Date, newEnd: Date, eventId: string) => {
		return orders.some((evt) => {
			if (evt.resource?.id === eventId) return false;
			if (evt.start && evt.end) {
				const evtEndPlus30 = addMinute(evt.end, 30);
				const startsBeforeEndsAfter =
					newStart < evtEndPlus30 && newStart >= evt.start;
				const endsAfterStartsBefore =
					newEnd > evt.start && newEnd <= addMinute(evt.start, 30);
				const surroundsEvent = newStart <= evt.start && newEnd >= evt.end;

				return startsBeforeEndsAfter || endsAfterStartsBefore || surroundsEvent;
			}
			return false;
		});
	};

	const handleEventMoveOrResize = useCallback(
		(type: "move" | "resize") => (args: EventInteractionArgs<Event>) => {
			const { event, start, end } = args;
			const now = new Date();

			const newStart = new Date(start);
			const newEnd = new Date(end);

			if (isEventOverlap(newStart, newEnd, event.resource.id)) {
				enqueueSnackbar(
					`No se puede ${
						type === "move" ? "mover" : "cambiar la hora"
					} debido a que interfiere con otra orden de servicio.`,
					{ variant: "warning" },
				);
				return;
			}

			if (newStart < now || newEnd < now) {
				enqueueSnackbar(
					`No se puede ${
						type === "move" ? "mover" : "cambiar la hora"
					} debido a que la hora o fecha no es actual o posterior.`,
					{ variant: "warning" },
				);
				return;
			}
			updateDateTimeOrder(event.resource.id, newStart, newEnd);
		},
		[orders],
	);

	const onNavigate = async (newDate: Date) => {
		setCurrentDate(newDate.toISOString());
	};

	const handleEventClick = (order: Event) => {
		handleOpenOrder(order as IEventOrder);
	};

	const onView = (view: View) => {
		setView(view);
	};

	useEffect(() => {
		setCurrentDate(new Date().toISOString());
	}, []);

	useEffect(() => {
		if (role) {
			if (currentDate) {
				const state = departments.find(
					({ value }) => value === selectedState[0],
				)?.text;
				const city = selectedCity[0];
				const status = selectedStatus[0];
				console.log(status);

				getOrders(
					currentDate,
					view,
					role === "contratista" ? company_id : undefined,
					state,
					city,
					status,
				);
			}
		}
	}, [
		currentDate,
		view,
		role,
		company_id,
		selectedState,
		selectedStatus,
		selectedCity,
	]);

	return (
		<Grid item xs={12}>
			<Box sx={{ height: "660px", position: "relative" }}>
				{loading && (
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							zIndex: 1,
						}}
					>
						<CircularProgress size={80} />
					</Box>
				)}
				<DnDCalendar
					popup
					step={5}
					formats={{
						agendaTimeRangeFormat: (
							range: { start: Date; end: Date },
							_culture,
							localizer,
						) => {
							if (!localizer) return "";
							return agendaTimeRangeFormat(range.start, range.end);
						},
						dayFormat: (date, culture, localizer) => {
							if (!localizer) return "";
							return (
								localizer
									.format(date, "dddd DD", culture)
									.charAt(0)
									.toUpperCase() +
								localizer.format(date, "dddd DD", culture).slice(1)
							);
						},
						weekdayFormat: (date, culture, localizer) => {
							if (!localizer) return "";
							return (
								localizer
									.format(date, "dddd", culture)
									.charAt(0)
									.toUpperCase() +
								localizer.format(date, "dddd", culture).slice(1)
							);
						},
						timeGutterFormat: "h:mm A",
						eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
							if (!localizer) return "";
							return `${localizer.format(
								start,
								"h:mm A",
								culture,
							)} - ${localizer.format(end, "h:mm A", culture)}`;
						},
					}}
					scrollToTime={new Date()}
					defaultDate={currentDate ? new Date(currentDate) : undefined}
					defaultView={view}
					length={view === "agenda" ? 7 : undefined}
					events={orders}
					localizer={localizer}
					onEventDrop={handleEventMoveOrResize("move")}
					onEventResize={handleEventMoveOrResize("resize")}
					resizable={false}
					selectable={false}
					draggableAccessor={() => false}
					startAccessor="start"
					endAccessor="end"
					showMultiDayTimes
					dayLayoutAlgorithm={"no-overlap"}
					messages={{
						showMore: (count) => `${count} más`,
						noEventsInRange: loading
							? "Cargando..."
							: "No hay ordenes de servicio en esta semana.",
						event: "Orden de servicio",
						time: "Hora",
						date: "Fecha",
					}}
					components={{
						toolbar: CustomToolbar,
						event: CustomEvent,
						timeGutterHeader: CustomTimeGutterHeader,
					}}
					eventPropGetter={getEventStyles}
					slotPropGetter={slotPropGetter}
					onNavigate={onNavigate}
					onSelectEvent={handleEventClick}
					onView={onView}
				/>
				{selectedOrder && !loading && <OrderItemModal />}
			</Box>
		</Grid>
	);
};

export default DynamicCalendar;
